// ######## SCROLL BAR RICERCA ######### //

$(document).keydown(function (e) {
    var value = e.which;
    // var selection_open = $(".selection .content-item").is(":visible");
    var selection_open = $(".selection .content-item:visible");
    var selection = $(".selection .content-item:visible").closest(".selection");

    var position_focus = 0;
    var found = false;
    var list_item = $(selection).find(".content-item .item:visible");
    var i = 0;
    var item = null;
    if (value === 38) // UP
    {
        if (selection_open) {
            for (i = 0; i < $(list_item).length; i++) {
                item = $(list_item)[i];
                (function (i) {
                    if (!found) {
                        if ($(item).hasClass("focus")) {
                            found = true;
                            position_focus = i;
                        }
                    }
                }(i));
                if (i === $(list_item).length - 1) {
                    if (found) {
                        position_focus = position_focus - 1;
                        position_focus = position_focus + 1;
                    } else {
                        position_focus = $(list_item).length;
                    }

                    if (position_focus <= $(list_item).length) {
                        $(list_item).removeClass("focus");
                        $(selection).find(".content-item .item:visible:nth-child(" + position_focus + ")").addClass("focus");
                    }
                }
            }
        }
        e.preventDefault(); // prevent the default action (scroll / move caret)

        if (position_focus > 6) {
            current_scroll = current_scroll - 34;
            $('.scrollbar-macosx').scrollTop(current_scroll);
        } else if (position_focus < 6) {
            current_scroll = current_scroll * position_focus;
        } else {
            $('.scrollbar-macosx').scrollTop(0);
        }
    }
    if (value === 40) // DOWN
    {
        if (selection_open) {
            for (i = 0; i < $(list_item).length; i++) {
                item = $(list_item)[i];
                (function (i) {
                    if (!found) {
                        if ($(item).hasClass("focus")) {
                            found = true;
                            position_focus = i;
                        }
                    }
                }(i));
                if (i === $(list_item).length - 1) {
                    if (found) {
                        position_focus = position_focus + 1;
                    }
                    position_focus = position_focus + 1;

                    if (position_focus <= $(list_item).length) {
                        $(list_item).removeClass("focus");
                        $(selection).find(".content-item .item:visible:nth-child(" + position_focus + ")").addClass("focus");
                    } else {
                        $(list_item).removeClass("focus");
                        // $(selection).find(".content-item .item:nth-child(1)").addClass("focus");
                    }
                }
            }
        }
        e.preventDefault(); // prevent the default action (scroll / move caret)

        if (position_focus > 6) {
            current_scroll = (34 * position_focus) - 34;
            $('.scrollbar-macosx').scrollTop(current_scroll);
        } else {
            $('.scrollbar-macosx').scrollTop(0);
        }
    }
    if (value === 13) // TASTO INVIO
    {
        $(selection).find(".item.focus").click().removeClass("focus");
        //e.preventDefault(); // prevent the default action (scroll / move caret)
    }
    if (value === 27) // TASTO ESC
    {
        $(list_item).removeClass("focus");
        $(".selection .content-item").hide();
        $(".selection").removeClass("active");
        e.preventDefault(); // prevent the default action (scroll / move caret)
    }
});

(function (document, window, $) {
    "use strict";
    $(document).ready(function () {
        if ($('.selection .content-item ul').length > 0) {
            try {
                $('.selection .content-item ul').scrollbar();
            } catch (e) {
                console.log(e);
            }
        }
        var current_url = window.location.href;
        if (current_url.indexOf("cart.php") < 0 && current_url.indexOf("store/order") < 0) {
            $('.selection .content-item.search').prepend('<div class="find"><input type="text" class="search-select" /></div>');
        }

        $(document).mouseup(function (e) {
            let container = $(".selection");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $(".selection .content-item").hide();

                try {
                    $(container).removeClass("active");
                } catch (e) {

                }
            }
        });

        $(document).on('bind input', '.selection .content-item.search input', function () {
            let current_url = window.location.href;
            if (current_url.indexOf("cart.php") < 0 && current_url.indexOf("store/order") < 0 && current_url.indexOf("cart") < 0) {
                let parent = $(this).closest(".content-item");
                let selector = $(parent).find("ul li");
                let current_input = "";
                let input_arr = [];
                $(parent).find(".item").removeClass("focus");
                try {
                    // current_input = "." + $(this).val().toLowerCase().trim();
                    current_input = $(this).val().toLowerCase().trim();
                    if (current_input.charAt(0) !== ".") {
                        current_input = "." + current_input;
                    }
                    for (let i = 0; i < current_input.length; i++) {
                        input_arr.push(current_input.charAt(i));
                    }
                } catch (e) {

                }

                $(selector).each(function () {
                    let item = $(this);
                    let value = $(item).find("div").html().toLowerCase();

                    let regex = new RegExp("^" + current_input);
                    let res = false;
                    if (regex.test(value)) {
                        res = true;
                    }
                    if (res) {
                        $(item).show();
                    } else {
                        $(item).hide();
                    }
                });
            }
        });

        $(document).on('click', '.selection .selected', function (e) {
            let current_url = window.location.href;
            if (current_url.indexOf("cart.php") < 0 && current_url.indexOf("store/order") < 0) {
                e.preventDefault();
                $(".selection .content-item").fadeOut();
                let parent = $(this).closest(".selection");
                let content = $(parent).find(".content-item");
                $(content).find(".item").removeClass("focus");
                $('.selection .content-item.search .find input').attr("placeholder", "Cerca estensione");
                if ($(content).find(".item").length > 0) {
                    if ($(content).is(":visible")) {
                        $(content).hide();
                        $(parent).removeClass("active");
                    } else {

                        $(content).fadeIn();
                        let first_item = $(content).find(".item:first-child");

                        $(first_item).focus();

                        if ($(parent).find(".content-item").hasClass("search")) {
                            $(parent).find(".content-item input").focus().val("");
                            $(parent).find(".content-item .item").show();
                        }

                        try {
                            let parent_header_lang = $(parent).closest("#select-language");
                            if ($(parent_header_lang).length > 0) {
                                $(parent).addClass("active");
                            }
                        } catch (e) {

                        }
                    }
                }
            }
        });

        $(document).on('click', '.selection .item', function (e) {
            e.preventDefault();
            let current_url = window.location.href;
            if (current_url.indexOf("cart.php") < 0 && current_url.indexOf("store/order") < 0) {
                let parent = $(this).closest(".selection");
                let content = $(this).find(".left").html();

                let extra = $(this).find(".left .badge-new").html();
                if (typeof extra !== "undefined") {
                    if (extra !== "") {
                        try {
                            let toReplace = '<span class="badge-new success">' + extra + '</span>';
                            content = content.replace(toReplace, '');
                            content = content.trim();
                        } catch (e) {

                        }
                    }
                }

                if ($(this).hasClass("new-gtld")) {
                    window.location.href = $(this).find("a").attr("href");
                } else {
                    if (typeof content !== "undefined") {
                        let adapt_string = false;

                        if (typeof $.FormWhois !== "undefined") {
                            adapt_string = $.FormWhois.defaults.adaptive_faq;
                        }

                        if (typeof $(this).closest(".selection").attr("id") !== "undefined") {
                            if ($(this).closest(".selection").attr("id") === "position_work") {
                                adapt_string = false;
                            }
                        }
                        if (adapt_string) {
                            if (content.length > 6) {
                                content = content.substring(0, 6) + " ...";
                            }
                        }
                        content = '<div class="left">' + content + '</div>';

                        $(parent).find(".item").removeClass("selected");
                        $(this).addClass("selected");
                        parent.find("div.selected").html(content).removeClass("first");
                        $(parent).find(".content-item").fadeOut();
                    }
                }
                try {
                    let parent_header_lang = $(parent).closest("#select-language");
                    if ($(parent_header_lang).length > 0) {
                        $(parent).removeClass("active");
                    }
                } catch (e) {

                }
            }
        });
    });

    $.Selected = function () { };
    $.Selected.defaults = {};
    $.Selected.prototype = {
    }
}(document, window, jQuery));
var SELECTED = new $.Selected;

